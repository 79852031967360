.App {
  font-family: 'Quicksand', sans-serif;
  overflow: hidden;
}

.App-header {
  background-color: #53a4b9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: min-content;
  font-family: 'Quicksand', sans-serif;
}

.App-header a{
  color: darkslategrey;
  text-decoration: none;
}

.name-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.socials {
  display: inline-block;
}

.socials a{
  margin-left: 10px;
}

.App-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Quicksand', sans-serif;
  margin-inline: 10vw;
  margin-bottom: 5vw;
}

.navbar {
  background-color: grey;
  display: flex;
  justify-content: left;
  padding-left: 10vw;
  overflow: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.navbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.navbar a {
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.navbar a:hover{
  color: darkgray;
}

.navbar .active {
  font-weight: bold;
  background-color: white;
}

.list {
  text-align: left;
}

.insta{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.training {
  text-align: left;
  font-size: large;
  font-weight: bold;
  overflow: scroll;
}

.news {
  border-bottom: #8a8989;
  border-bottom-style: dashed;
}

.news a {
  text-decoration: none;
}

.news a:hover{
  color: darkgray;
}

.loadMoreBtn {
  margin-top: 10px;
  width: fit-content;
}

.gallery {
  margin-top: 20px;
  display: flex;
}

.reel {
  width: 500px;
}

form {
  text-align: left;
}
form input, textarea {
  display: flex;
  width: 40vw;
  padding : 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

form textarea {
  height: 150px;
  padding: 12px 20px;
  
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: none;
  vertical-align: text-top;
}

form button {
  padding: 10px;
  font-family: 'Quicksand';
}


@media (max-width: 450px) {
  .insta {
    flex-direction: column;
    width: 320px;
    justify-content: center;
  }

  .training {
    width: fit-content;
  }

  .reel {
    width: 320px;
  }
}